import Logo from '../images/logo.sg.svg';

export default {
  brand: 'Tenpay Global Pte. Ltd.',
  logoUrl: Logo,
  cookiePolicy: 'SgCookiePolicy',
  PrivacyPolicy: 'SgPrivacyPolicy',
  AboutUs: 'SgAboutUs',
  Date: '2023',
  timezone: 'Asia/Singapore',
  agreementDownloadLink: 'https://wpsg-static-1306330437.cos.ap-singapore.myqcloud.com/sign/protocol/WeChat%20Payment%20Service%20Agreement%20-%20Tenpay%20Global%20and%20Merchant%20.pdf',
} as const;
