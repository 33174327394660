import { CSRF_TOKEN_KEY } from '@src/common';
import { message } from 'antd';
import axios, { AxiosError, Canceler } from 'axios';
import qs from 'qs';

const service = axios.create({
  baseURL: '/',
  timeout: 10000,
  withCredentials: true,
});

const { CancelToken } = axios;
const pendingRequest = new Map<string, Canceler>();
// 获取请求key
function getReqKey(config) {
  // 请求方式、请求地址、请求参数生成的字符串来作为是否重复请求的依据
  const { method, url, params, data } = config; // 解构参数
  const requestKey = [method, url, qs.stringify(params), qs.stringify(data)].join('&');
  return requestKey;
}

// 取消重复请求
function removeReqKey(key) {
  const cancelToken = pendingRequest.get(key);
  cancelToken!(key); // 取消之前发送的请求
  pendingRequest.delete(key); // 请求对象中删除requestKey
}

/**
 * @description 网络请求异常消息处理
 * @param err
 * @returns boolean 返回true表示需要提示错误信息，返回false表示统一做错误提示，后续无需处理
 */
function handleResponseError(err: AxiosError) {
  switch (err.response?.status) {
    case 500:
      void message.error('System error');
      return false;
    case 502:
      void message.error('System not response');
      return false;
    case 403:
      window.open('/merchants/login', '_self');
      return false;
  }

  if (axios.isCancel(err)) {
    console.log('requests are too frequent,request cancel');
    return false;
  }

  if (err.code === 'ECONNABORTED') {
    void message.error('Request timeout');
    return false;
  }

  return true;
}

// 添加请求拦截器
service.interceptors.request.use(
  async (config) => {
    const { headers } = config;

    if (headers) {
      headers['csrf-token'] = localStorage.getItem(CSRF_TOKEN_KEY) || '';
    }

    // 判断是否是重复请求，避免重复提交
    const requestKey = getReqKey(config);
    if (pendingRequest.has(requestKey)) {
      // 是重复请求
      removeReqKey(requestKey); // 取消
    } else {
      // 设置cancelToken
      // eslint-disable-next-line no-param-reassign
      config.cancelToken = new CancelToken((cancel) => {
        pendingRequest.set(requestKey, cancel);
      });
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// 添加响应拦截器
service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => Promise.reject(error),
);

export default {
  /**
   *
   * @param url
   * @param params
   * @param successInfo
   * @param failInfo
   * @returns
   */
  get<T>(url: string, params: any, successInfo = '', failInfo = ''): Promise<T> {
    return new Promise((resolve, reject) => {
      service({
        method: 'get',
        url,
        params,
        responseType: 'json',
      })
        .then((res) => {
          successInfo && void message.success(successInfo);
          resolve(res.data as T);
        })
        .catch((err) => {
          if(handleResponseError(err)){
            message.error(err.response?.data.message || failInfo || err.message);
            reject(err);
          }
        });
    });
  },
  /**
   *
   * @param url
   * @param data
   * @param successInfo
   * @param failInfo
   * @returns
   */
  post<T>(url: string, data: any, successInfo = '', failInfo = ''): Promise<T> {
    return new Promise((resolve, reject) => {
      service({
        method: 'post',
        url,
        data,
      })
        .then((res) => {
          successInfo && void message.success(successInfo);
          resolve(res.data as T);
        })
        .catch((err) => {
          if(handleResponseError(err)){
            message.error(err.response?.data.message || failInfo || err.message);
            reject(err);
          }
        });
    });
  },
  /**
   *
   * @param url
   * @param data
   * @param fileName
   * @param successInfo
   * @param failInfo
   * @returns
   */
  downloadFile(url: string, data: any, fileName = 'default.txt', successInfo = '', failInfo = '') {
    return new Promise(() => {
      service({
        method: 'get',
        url,
        data,
        responseType: 'blob',
      })
        .then((res) => {
          const blob = new Blob([res.data]);
          const dom = document.createElement('a');
          const blobUrl = window.URL.createObjectURL(blob);
          dom.href = blobUrl;
          dom.download = decodeURI(fileName);
          dom.style.display = 'none';
          document.body.appendChild(dom);
          dom.click();
          dom.parentNode?.removeChild(dom);
          window.URL.revokeObjectURL(blobUrl);
          successInfo && void message.success(successInfo);
        })
        .catch((err) => {
          handleResponseError(err) && message.error(err.response?.data.message || failInfo || err.message);
        });
    });
  },
};
